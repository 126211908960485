import axios from "~/plugins/axios";
export default {
  checkCoupon() {
    return axios
      .get("/ebsn/api/personal-coupon/check_coupon")
      .then(data => {
        return data.data.data;
      })
      .catch(() => {
        return false;
      });
  },
  addGiftCertificate(giftCertificateId, cartItemId) {
    let items = [{ userGiftCertificateId: giftCertificateId, cartItemId }];
    return axios
      .post("/ebsn/api/personal-coupon/add_coupon", {
        userGiftCertificates: items
      })
      .then(data => {
        return data.data.data.gift_certificates;
      })
      .catch(err => err);
  },
  addGiftCertificates(items) {
    return axios
      .post("/ebsn/api/personal-coupon/add_coupon", {
        userGiftCertificates: items
      })
      .then(data => {
        return data.data.data.gift_certificates;
      })
      .catch(err => err);
  },
  getGiftCertificate() {
    return axios
      .get("/ebsn/api/personal-coupon/get_coupon")
      .then(data => {
        return data.data.data.gift_certificates;
      })
      .catch(err => err);
  }
};
//   .module("ebsn.service.tigros")
//   .factory("TigrosPersonalCouponService", function($q, $resource, UrlFactory) {
//     var couponApi = $resource(
//       UrlFactory.addEbsnUrl("/api/personal-coupon/:action"),
//       null,
//       {
//         checkCoupon: {
//           method: "GET",
//           params: { action: "check_coupon" },
//           cache: false
//         },
//         getCoupon: {
//           method: "GET",
//           params: { action: "get_coupon" },
//           cache: false
//         },
//         addCoupon: {
//           method: "POST",
//           params: { action: "add_coupon" },
//           cache: false
//         }
//       }
//     );

//     var factory = {};

//     factory.getGiftCertificate = function(giftCertificateId) {
//       var deferred = $q.defer();

//       couponApi.getCoupon(
//         {},
//         function(data) {
//           if (data.data.gift_certificates) {
//             for (var j = 0; j < data.data.gift_certificates.length; j++) {
//               var giftCertificate = data.data.gift_certificates[j];
//               for (var i = 0; i < giftCertificate.cartItems.length; i++) {
//                 var cartItem = giftCertificate.cartItems[i];
//                 if (cartItem.quantity > 0) {
//                   cartItem.totalItems = cartItem.quantity;
//                 } else {
//                   cartItem.totalItems = 1;
//                 }
//               }
//             }
//             deferred.resolve(data.data.gift_certificates);
//           } else {
//             deferred.reject();
//           }
//         },
//         function() {
//           deferred.reject();
//         }
//       );

//       return deferred.promise;
//     };

//     factory.setGiftCertificate = function(giftCertificates) {
//       var deferred = $q.defer();
//       //questo è sbagliato perchè potrei mandarti più certificati nello stesso momento...il tipo devi capirlo dal certificato che ti mando
//       var items = [];
//       for (var j = 0; j < giftCertificates.length; j++) {
//         var giftCertificate = giftCertificates[j];
//         for (var i = 0; i < giftCertificate.userGiftCertificates.length; i++) {
//           var userGiftCertificate = giftCertificate.userGiftCertificates[i];
//           if (userGiftCertificate.cartItem) {
//             var item = {};
//             item["userGiftCertificateId"] =
//               userGiftCertificate.userGiftCertificateId;
//             item["cartItemId"] = userGiftCertificate.cartItem.cartItemId;
//             items.push(item);
//           }
//         }
//       }
//       couponApi.addCoupon(null, { userGiftCertificates: items }, function(
//         data
//       ) {
//         if (data.data) {
//           //ok
//           deferred.resolve(data.data.gift_certificates);
//         } else {
//           deferred.reject([]);
//         }
//       });

//       return deferred.promise;
//     };

//     factory.checkCoupon = function() {
//       var deferred = $q.defer();

//       couponApi.checkCoupon(
//         {},
//         function(data) {
//           deferred.resolve();
//         },
//         function() {
//           deferred.reject();
//         }
//       );

//       return deferred.promise;
//     };

//     return factory;
//   });
